var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { "align-content": "center", justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "text-center", attrs: { cols: "12" } },
            [
              _vm._t("default", function () {
                return [_vm._v("Loading data")]
              }),
            ],
            2
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-progress-linear", {
                attrs: { indeterminate: "", rounded: "", height: "6" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }